.grid-container {
  @include clearfix;
  margin: 0 auto;
  max-width: $grid-max-width;
}

.container-fluid,
.container {
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  padding-left: var($grid-gutter, 2rem);
  padding-right: var($grid-gutter, 2rem);
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: $grid-gutter * .1;
  margin-right: $grid-gutter * .1;
  position: relative;

  &.reverse {
    flex-direction: row-reverse;
  }

  &.sticky {
    background: $white;
    position: sticky;
    top: -5px;
    z-index: 9999;
  }
}

.row-centered {
  align-items: center;
}

.col {
  padding: 10px;
  position: relative;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-left: $grid-gutter * .5;
  padding-right: $grid-gutter * .5;
}

.col-lg {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

// Generate Columns
@each $breakpoint in $breakpoints {
  $declaration: nth($breakpoint, 1);
  $columns-reduced-by: nth($breakpoint, 2);

  @media only screen and #{$declaration} {
    @include grid-loop($columns-reduced-by);
  }
}

.start-lg {
  justify-content: flex-start;
  text-align: start;
}

.center-lg {
  justify-content: center;
  text-align: center;
}

.end-lg {
  justify-content: flex-end;
  text-align: end;
}

.top-lg {
  align-items: flex-start;
}

.middle-lg {
  align-items: center;
}

.bottom-lg {
  align-items: flex-end;
}

.around-lg {
  justify-content: space-around;
}

.between-lg {
  justify-content: space-between;
}

.first-lg {
  order: -1;
}

.last-lg {
  order: 1;
}

.col-grey {
  background-color: $light-grey;
}

.col-title {
  margin-bottom: 20px;
  padding: 15px;
}
