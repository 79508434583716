.no-tour {
  .reactour__helper {
    display: none;
  }
}

.reactour__helper--is-open {
  max-width: 800px!important;

  .grid-container {
    padding: 0;
  }

  p {
    font-size: 16px;
  }

  ul {
    padding-left: 40px;
  }
}
