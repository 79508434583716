.query-builder-container  {
  background-color: $white;
  color: $secondary-brand-color;
  vertical-align: top;
  width: 100%;

  .add-rule,
  .add-group,
  .remove-rule,
  .remove-group {
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 0;
    margin-right: 10px;
    padding: 6px 12px;
    text-align: center;
    text-transform: capitalize;
    touch-action: manipulation;
    transition: $button-transition;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }

  .add-rule,
  .add-group {
    background-color: transparent;
    color: $secondary-brand-color;
    min-width: 100px;
    text-decoration: underline;

    // &:hover {
    //   background-color: $success-color-hover;
    // }
  }

  .remove-rule {
    background-color: $error-color;
    border-radius: 50%;
    content: 'remove';
    font-size: 10px;
    height: 20px;
    line-height: 20px;
    margin-top: 10px;
    min-width: inherit;
    padding: 0;
    width: 20px;

    &:hover {
      background-color: $error-color-hover;
    }
  }

  .remove-group {
    background-color: transparent;
    color: $error-color;

    &:hover {
      text-decoration: underline;
    }
  }

  input {
    margin-right: 10px;
    width: auto;
  }

  .combinators,
  .fields,
  .operators,
  .value {
    margin-right: 10px;
  }

  .combinators {
    width: 150px;
  }

  .fields {
    width: 250px;
  }

  .operators {
    width: 60px;
  }

  .rule,
  .rule-group {
    margin: 10px 0;
    width: 100%;
  }

  .rule-group {
    border: 1px solid $border-color-light;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 12px;
  }

  .rule {
    margin-left: 10px;
  }
}
