.borderBottom {
  border-bottom: solid 1px $newLightGrey;
  margin: 0 -22px 0 -22px;
  padding: 0 12px 0 12px;
}

.borderAllSides {
  border: solid 1px $newLightGrey;
}

.borderLeft {
  border-left: solid 1px $newLightGrey;
}
