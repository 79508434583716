.row {
  &.events-row {
    .dropdown-container {
      margin-left: -4px;
      margin-top: -10px;
      width: 100%;
    }

    .dropdown-container-content {
      padding: 10px;
      text-align: left;

      &::before {
        display: none;
      }
    }

    select {
      border-bottom: 1px solid $secondary-brand-color;
    }
  }
}
