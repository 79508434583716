.spinner,
.spinner-message {
  margin-left: 240px;
}

.spinner-message {
  max-width: 350px;

  &-container {
    background: $white;
    box-shadow: 4px 4px 8px $shadow-rgba;
    padding: 10px;
    text-align: center;
  }
}

.sk-fading-circle {
  height: 40px;
  margin: 100px auto;
  position: relative;
  width: 40px;

  .sk-circle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
      animation: skCircleFadeDelay 1.2s infinite ease-in-out both;
      background-color: $secondary-brand-color;
      border-radius: 100%;
      content: '';
      display: block;
      height: 15%;
      margin: 0 auto;
      width: 15%;
    }
  }
}

// Animation Loop
@for $i from 2 through 12 {
  .sk-fading-circle {
    .sk-circle#{$i} {
      transform: rotate((30deg * ($i - 1)));

      &::before {
        animation-delay: (-1.1s - .1s * ($i - 1));
      }
    }
  }
}

@keyframes skCircleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}
