.flex {
  display: flex;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.alignVMiddle {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.alignVMiddleLeft {
  vertical-align: middle;
  justify-content: flex-start;
  align-items: center;
}
.alignVMiddleRight {
  vertical-align: middle;
  justify-content: flex-end;
  align-items: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.alignCenter {
  align-items: center;
}

.w-100 {
  width: 100%;
}
