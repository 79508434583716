.btn {
  background-image: none;
  border: 1px solid transparent;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  height: 35px;
  line-height: 24px;
  margin-bottom: 0;
  min-width: 150px;
  padding: 5px 12px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition: $button-transition;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  i {
    &.material-icons {
      font-size: 24px;
      left: 20px;
      position: absolute;
    }
  }

  &-back {
    min-width: 100px;
  }

  &-secondary {
    background-color: transparent;
    border: 1px solid $dark-blue;
    color: $dark-blue;

    &:hover,
    &:focus {
      background-color: $dark-blue;
      color: $white;
    }
  }
  &-new-secondary {
    background-color: $secondaryBtn;
    color: $white;

    &:hover,
    &:focus {
      background-color: $secondaryBtnHover;
      color: $white;
    }
  }

  &-success {
    background-color: $green;

    &:hover,
    &:focus {
      background-color: $dark-green;
    }
  }

  &-error {
    background-color: $red;

    &:hover,
    &:focus {
      background-color: $dark-red;
    }
  }

  &-blue {
    background-color: $blue;

    &:hover,
    &:focus {
      background-color: $dark-blue;
    }
  }

  &-disabled,
  &:disabled {
    background-color: $field-disabled-background;
  }

  &-full-width {
    width: 100%;
  }

  &-fixed-width {
    width: 300px;
  }

  &-with-icon {
    i {
      float: right;
    }
  }

  &-login {
    background-color: $yobota-color;
    font-size: 24px;
    font-weight: 400;
    height: 52px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    min-width: 350px;

    &:hover,
    &:focus {
      background-color: $yobota-color-hover;
    }
  }
}

a,
button {
  &:focus {
    .btn {
      &-success {
        background-color: $dark-green;
        box-shadow: 0 0 3pt 2pt $green;
      }

      &-error {
        background-color: $dark-red;
        box-shadow: 0 0 3pt 2pt $red;
      }

      &-blue {
        background-color: $dark-blue;
        box-shadow: 0 0 3pt 2pt $blue;
      }
    }
  }
}

.badge {
  background: $grey-blue;
  border-radius: 5px;
  color: $white;
  font-size: 12px;
  font-weight: 900;
  line-height: 16px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &-success {
    background: $green;
  }

  &-error {
    background: $red;
  }

  &-primary {
    background: $primary-brand-color;
  }

  &-secondary {
    background: $dark-blue;
  }

  &-notice {
    background: $highlight-brand-color;
  }
}

.indicator-circle {
  background-color: $white;
  border-radius: 20px;
  content: ' ';
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  width: 12px;

  &-success {
    background: $green;
  }

  &-error {
    background: $red;
  }

  &-primary {
    background: $primary-brand-color;
  }

  &-secondary {
    background: $dark-blue;
  }

  &-notice {
    background: $highlight-brand-color;
  }
}

.text-button {
  appearance: none;
  border: 0;
  background-color: transparent;
}
