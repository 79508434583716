@import '../base/colors';

.inbound-payment-transactions-container {
  .grid-container {
    max-width: unset;
  }

  .accordion-section-container {
    overflow: hidden;

    .accordion-section-content {
      padding: 0;

      .row {
        margin-right: 0;
      }
    }

    .accordion-title {
      padding-left: 10px;
    }
  }

  .inbound-payment-transactions-list {
    .data-table {
      .checkbox-container {
        height: auto;

        .checkmark {
          top: 4px;
        }
      }

      .expander-icon {
        right: 10px;
      }
    }
  }
}
