
$green: #03ac52;
$dark-green: darken($green, 10);
$blue: #337bff;
$dark-blue: #2a7cff;


.datePeriodPicker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    &__label {
        color: #999;
        margin: 0 5px 0 15px;
    }
    &__btn {
        border: none;
        padding: 4px 20px;
        border-radius: 5px;
        margin-left: 10px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        &--green {
            background: $green;
            &:hover {
                background: $dark-green;
            }
        }
        &--blue {
            background: $blue;
            &:hover{
                background: $dark-blue;
            }
        }
    }
}