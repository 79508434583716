@import '../base/colors';

.deposit-bullet-container {
  padding-top: 25px;

  .period-tracker {
    height: 1px;
    position: absolute;
    top: 145px;

    .dropdown-container {
      left: -106px;
    }

    .dropdown-container-content {
      background: $background-grey;
      border: 0;
      width: 200px;

      &::before {
        border-bottom: 30px solid $background-grey;
        left: 50%;
        top: -30px;
      }
    }
  }
}

.deposit-bullet-chart {
  align-items: center;
  background-color: $dark-grey;
  border-radius: 25px;
  display: flex;
  height: 30;
  justify-content: center;
  overflow: hidden;
  width: 100%;

  .info-box-container {
    z-index: unset;

    .material-icons {
      font-size: 14px;
      margin-left: 0;
      margin-right: 0;
    }

    .dropdown-container {
      bottom: 30px;
      left: -70px;
      top: unset;

      .dropdown-container-content {
        background-color: $background-grey;
        border: 0;
        border-radius: 5px;
        text-align: center;
        width: 200px;

        &::before {
          border-bottom: unset;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 17px solid $background-grey;
          display: unset;
          left: 70px;
          top: 100%;
        }
      }
    }
  }
}
