.main-container {
  background-color: $background-grey;
  height: calc(100vh - 90px);
  margin-left: 249px;
  margin-top: 84px;
  min-height: 500px;
  overflow: auto;
  overflow-y: scroll;
  padding: 16px 32px;
  padding-top: 0;
  width: calc(100% - 249px);

  &.story {
    margin-left: 0;
    margin-top: 0;
    width: auto;
  }

  .card-rounded {
    border: 0;
  }

  .main-container-card {
    background: $white;
    border-radius: 5px;
    padding: 16px;
    position: relative;
    transition: opacity 0.25s ease-in-out;
  }

  .main-container-content {
    @extend .main-container-card;
    min-height: 250px;
  }
}

.tabs-container {
  .tabs-nav {
    background-color: $white;
  }

  .tabs-active-content {
    padding: 24px 0 !important;

    .card-rounded {
      background-color: $white;
      padding: 16px;
    }
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
    opacity: 0.1;
    transform: rotate(0deg);
  }

  to {
    background-position: 100% 0;
    opacity: 1;
    transform: rotate(359deg);
  }
}

.trl {
  .main-container {
    // notlikethis
    background-color: transparent;
    overflow-x: hidden;

    &::before {
      animation: animatedBackground 10s linear infinite;
      background-color: $yobota-color;
      background-image: '/images/bg-help.png';
      background-size: 40px;
      content: '';
      height: 200%;
      left: -50%;
      position: absolute;
      top: -50%;
      width: 200%;
      z-index: -1;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid $field-normal-border;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

.page-title {
  color: $dark-blue;
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  height: 35px;
  line-height: 24px;
  margin-bottom: 0;
  padding: 5px 12px;
  text-align: center;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
}

.atlwdg-trigger {
  &.atlwdg-SUBTLE {
    background: $white !important;
    box-shadow: 0 10px 20px $overlay-color-rgba, 0 6px 6px $light-shadow-rgba !important;
    font-family: unset !important;
    padding: 5px 15px !important;
  }
}
