.data-table {
  .row {
    &.header,
    &.data-row,
    &.footer {
      margin-left: 0;
    }
  }

  .header,
  .data-row,
  .footer {
    .col {
      border-right: 1px solid $light-shadow-rgba;
      margin: 0;
      padding: 4px;
      word-wrap: break-word;

      &:last-of-type {
        border-right: 0;
      }
    }

    i {
      cursor: pointer;
    }
  }

  .table-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;

    .control-columns {
      align-items: center;
      border-right: 1px solid $light-shadow-rgba;
      display: flex;
      justify-content: center;
      padding-left: 15px;
      padding-right: 9px;
      position: relative;
      width: auto;

      &:last-of-type {
        border-right: 0;
      }
    }
  }

  .header {
    background-color: $light-grey;
    border-bottom: 1px solid $background-grey-hover;
    margin-bottom: 3px;
    z-index: 1;

    &.sticky {
      position: sticky;
      top: 0;
    }

    .col {
      min-height: 50px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      max-width: 130px;
      padding-left: 5px;
      text-align: left;
    }

    .info-box-container {
      position: absolute;
      right: 0;
      top: 7px;
      z-index: unset;

      .dropdown-container-content {
        margin-left: 10px;
        width: 210px;
      }

      i {
        color: $secondary-brand-color-lighter;
        font-size: 16px;
        margin-left: 2px;
        vertical-align: top;
      }

      p {
        max-width: none;
      }
    }
  }

  .footer {
    .col {
      border-right: 0;
      display: flex;
      margin: 15px 0;

      &:last-of-type {
        justify-content: space-around;
      }
    }
  }

  .data-row {
    &.editing,
    &.active {
      background-color: $light-grey;
    }

    &:hover {
      background-color: $background-grey;
    }

    &.success {
      background-color: $success-color;

      p {
        color: $white;
      }
    }

    &.data-row-expanded-section {
      background-color: $white;
    }

    &.hoverable {
      &:hover {
        text-decoration: none;
      }
    }

    .data-actions {
      align-items: center;
      display: flex;
      justify-content: space-around;
    }

    .badge {
      margin: 0;
    }
  }

  input,
  select {
    appearance: none;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $light-shadow-rgba;
    border-radius: 0;
    font-size: 14px;
    height: auto;
    line-height: 16px;
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 5px;
    width: 100% !important;

    &:focus {
      border-bottom: 1px solid $secondary-brand-color;
    }

    &.error {
      border-bottom: 1px solid $error-color;
    }
  }

  select {
    background-image: url('../assets/icons/arrow_blue.png');
    background-size: 10px;
  }

  input[type='number'] {
    &::-webkit-inner-spin-button {
      background-image: url('../assets/icons/arrow_numbers_blue.png');
      background-position: center right 5px;
      background-size: 10px;
    }
  }
}

.data-table.dynamic-table.expandable .data-row-main .col:first-of-type {
  padding-left: 25px !important;
}
