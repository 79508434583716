$padding-amounts: 10px, 20px, 40px, 50px, 80px;
  @for $i from 1 through length($padding-amounts) {
    $pn: nth($padding-amounts, $i);
    .px-#{$i} {
      padding-left: $pn;
      padding-right: $pn;
    }
  }

@for $i from 1 through length($padding-amounts) {
  $pn: nth($padding-amounts, $i);
  .py-#{$i} {
    padding-top: $pn;
    padding-bottom: $pn;
  }
}

$margin-amounts: .5rem, 1rem, 1.5rem, 2rem;
@for $i from 1 through length($margin-amounts) {
  $mn: nth($margin-amounts, $i);
  .mx-#{$i} {
    margin-left: $mn;
    margin-right: $mn;
  }
}

@for $i from 1 through length($margin-amounts) {
  $mn: nth($margin-amounts, $i);
  .my-#{$i} {
    margin-top: $mn;
    margin-bottom: $mn;
  }
}

.p-0 {
  padding: 0 !important;
}
