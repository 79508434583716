.dialog-container {
  .dialog-content {
    padding: 28px 5px;
  }
}

.event-stepper-container {
  overflow-y: auto;

  p {
    &.small {
      font-size: 12px !important;
    }
  }

  &.grid-container {
    max-width: unset;
  }

  .data-table-select__menu {
    height: auto;
    max-width: none;
    min-width: 450px;
    width: auto;
  }

  .badge-blue {
    background-color: $highlight-brand-color;
  }

  .stepper-column {
    background-color: $white;
    border-radius: 5px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;

    .card {
      border: 0;
      min-height: 350px;
    }
  }

  .bottom-line {
    &-light-blue {
      border-bottom: 2px solid #7eadfb;
    }

    &-blue {
      border-bottom: 2px solid #79cceb;
    }

    &-orange {
      border-bottom: 2px solid #f5a968;
    }

    &-light-green {
      border-bottom: 2px solid #93cfb1;
    }

    &-light-pink {
      border-bottom: 2px solid #fd8ca6;
    }

    &-green {
      border-bottom: 2px solid $success-color;
    }

    &-red {
      border-bottom: 2px solid $error-color;
    }
  }

  .stepper-line {
    padding: 5px 0;

    &:hover,
    &.selected {
      background-color: $light-grey;
      box-shadow: 2px 2px 4px $shadow-rgba;
      cursor: pointer;
    }
  }
}

