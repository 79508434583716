.overlay {
  align-items: center;
  background: $overlay-color-rgba;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  padding-bottom: 200px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.dialog-container {
  .dialog-content {
    border-radius: 5px;

    p {
      color: $dark-blue;
    }
  }
}
