.input-holder {
  width: 100%;
}

.plus-select {
  background-image: url('../assets/icons/circle_plus_green.png');
  background-position: center right 10px;
  background-repeat: no-repeat;
  max-width: 450px;
  width: 100%;

  &:hover {
    background-image: url('../assets/icons/circle_plus_green.png');
  }

  &:focus {
    background-image: url('../assets/icons/circle_plus_green.png');
  }
}

// Custom checkboxes
.checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 22px;
  height: 40px;
  min-width: 20px;
  padding-left: 30px;
  position: relative;
  user-select: none;
  white-space: normal;

  &:hover {
    background: transparent;
  }

  input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: auto;

    &:checked ~ .checkmark::after {
      display: block;
    }

    &:focus ~ .checkmark {
      background-color: $white;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
      background-color: transparent;
      border: none;
    }
  }

  span.disabled {
    opacity: 0.5;
  }

  .checkmark {
    background-color: $white;
    border: 1px solid $border-color-light;
    border-radius: 5px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 20px;

    &::after {
      border: solid $secondary-brand-color;
      border-width: 0 3px 3px 0;
      content: '';
      display: none;
      height: 8px;
      left: 6px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 3px;
    }
  }

  &:hover input ~ .checkmark {
    background-color: $background-grey-hover;
  }

  &:hover input:disabled ~ .checkmark {
    background-color: transparent;
  }
}

span {
  &.input-error,
  &.input-warning {
    text-align: left;
  }

  &.input-error {
    color: $error-color;
  }

  &.input-warning {
    color: $warning-color;
  }
}

input,
select,
textarea {
  appearance: none;
  background-color: $field-normal-background;
  border: 1px solid $field-normal-border;
  border-radius: 5px;
  color: $dark-blue;
  display: block;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  margin: 5px 0;
  max-width: 100%;
  padding: 0 5px;
  vertical-align: middle;

  &:hover {
    background-color: $field-hover-background;
    border: 1px solid $field-hover-border;
  }

  &:focus {
    background-color: $white;
    border: 1px solid $field-hover-border;
  }

  &:disabled {
    background-color: $field-disabled-background;
    border: 1px solid $field-disabled-border;
  }

  &::placeholder {
    color: $grey-blue;
  }

  &.error {
    border: 1px solid $red;
  }

  &.warning {
    border: 1px solid $orange;
  }
}

.react-select__control {
  appearance: none;
  background-color: $field-normal-background !important;
  border: 1px solid $field-normal-border;
  border-radius: 5px;
  color: $dark-blue;
  display: block;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 14px;
  line-height: 35px;
  margin: 5px 0;
  max-width: 100%;
  padding: 0 5px;
  vertical-align: middle;

  .react-select__clear-indicator {
    display: none;
  }

  .react-select__value-container {
    display: block;
    min-height: 20px;

    .react-select__input {
      height: 20px;

      input {
        height: 20px;
      }
    }

    .react-select__multi-value {
      background: transparent;
      line-height: 20px;

      .react-select__multi-value__remove {
        background: transparent !important;
      }
    }
  }

  &:hover {
    border-color: $field-hover-border !important;
  }

  &.react-select__control--is-focused {
    background-color: $white !important;
    border-color: $field-hover-border;
    box-shadow: 0 0 0 1px $field-hover-border;
  }
}

// Select speciffic styles
select {
  background-image: url('../assets/icons/arrow_grey.png');
  background-position: center right 10px;
  background-repeat: no-repeat;
  padding-right: 37px;

  &:hover {
    background-image: url('../assets/icons/arrow_blue.png');
  }

  &:focus {
    background-image: url('../assets/icons/arrow_blue.png');
  }
}

input {
  // Number input specific styles
  &[type='number'] {
    padding-right: 20px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      background-image: url('../assets/icons/arrow_numbers_grey.png');
      background-position: center right 10px;
      background-repeat: no-repeat;
      bottom: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 2em;
    }

    &:hover,
    &:focus {
      &::-webkit-inner-spin-button {
        background-image: url('../assets/icons/arrow_numbers_blue.png');
      }
    }
  }

  // Date time input specific styles
  &[type='date'],
  &[type='time'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  &[type='datetime-local'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

// In form fields
form {
  select {
    width: 300px;
  }

  input,
  textarea {
    width: 450px;
  }

  input {
    &[type='datetime-local'],
    &[type='date'],
    &[type='time'],
    &[type='number'] {
      width: 300px;
    }
  }

  textarea {
    line-height: 18px;
    min-height: 110px;
    padding: 5px;
    resize: vertical;
  }

  label {
    white-space: nowrap;

    p {
      color: $dark-blue !important;
      display: inline-block;
      font-weight: 900;
      margin-bottom: 5px;
    }

    .material-icons {
      font-size: 18px;
    }
  }
}

.jsoneditor {
  border: thin solid $dark-blue;

  .jsoneditor-menu {
    background-color: $dark-blue;

    button {
      display: none;
    }
  }
}
