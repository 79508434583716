// Typography
body {
  color: $font-color;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  font-variant: normal;
}

h1 {
  font-size: 28px;
  font-weight: 900;
}

h2 {
  color: $dark-blue;
  font-size: 24px;
  font-weight: 900;
}

h3 {
  color: $dark-blue;
  font-size: 20px;
  font-weight: 900;
  &.subtitle {
    font-size: 16px;
  }
}

h4 {
  font-size: 18px;
  font-weight: 900;
}

h1,
h2,
h3,
h4 {
  margin: 10px 0;
}

p {
  color: $dark-blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
  margin-top: 5px;

  &.small {
    i {
      font-size: 24px;
      line-height: 18px;
      vertical-align: top;
    }
  }

  &.bold {
    font-weight: 900;
  }

  .material-icons {
    font-size: 20px;
    vertical-align: bottom;
  }
}

h1,
h2,
h3,
h4,
p,
span,
i {
  &.white {
    color: $white !important;
  }

  &.blue {
    color: $dark-blue !important;
  }

  &.grey {
    color: $grey-blue !important;
  }

  &.green,
  &.success {
    color: $green !important;
  }

  &.red,
  &.error {
    color: $red !important;
  }

  &.warning {
    color: $orange !important;
  }

  &.notice {
    color: $highlight-brand-color !important;
  }

  &.transparent {
    color: transparent !important;
  }

  &.primaryBrandColor {
    color: $primary-brand-color !important;
  }
  &.newGrey {
    color: $newGrey !important;
  }
  &.newDarkBlue {
    color: $newDarkBlue !important;
  }

  &.hoverable-list {
    color: $dark-blue;

    &:hover {
      color: $highlight-brand-color;
    }
  }

  &.material-icons {
    .wf-loading & {
      display: none;
    }
    .wf-active & {
      @keyframes fadeIn {
        0% {
          color: rgba($color: #fff, $alpha: 0);
        }
      }
      animation: fadeIn 0.5s ease-in-out 1;
    }
  }
}

span {
  &.pagination-button {
    &:hover {
      color: $white !important;
    }
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: 900;
}

.text-regular {
  font-weight: 500 !important;
}

.text-wrap {
  overflow-wrap: break-word;
}

.capitalised {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.hoverable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.material-icons {
  vertical-align: middle;

  &.circle-icon {
    background-color: $secondary-brand-color;
    border-radius: 25px;
    color: $white;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
  }
}

.pagination-container {
  .selected {
    color: $white !important;
  }
}

.font-16 {
  font-size: 16px;
}

.valign-bottom {
  vertical-align: bottom;
}

.text-capitalize {
  text-transform: capitalize;
}
