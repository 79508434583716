* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small,
strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label,
legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas,
details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  background-color: $background-grey;
  font-family: Arial, sans-serif;
  line-height: 1;
}

a {
  background: transparent;
  color: $white;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
}

img {
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.no-margins {
  margin: 0 !important;
}

::selection {
  background: #01d26344;
}

::-moz-selection {
  background: #01d26344;
}

.react-json-view {
  font-family: inherit!important;
}
