// Backgrounds & typography
$dark-grey: #cacaca;
$background-grey: #f0f1f4;
$background-grey-hover: #d9dcd9;
$font-color: #01233d;

// Button and font colors
$success-color: #093;
// $success-color: #01d263;
$success-color-hover: darken($success-color, 10);
// $success-color-hover: darken($success-color, 15);
$success-color-outline: lighten($success-color, 15);

$error-color: #b92424;
$error-color-hover: darken($error-color, 8);
$error-color-outline: lighten($error-color, 15);

$warning-color: #ffc107;

// Brand colors
$yobota-color: #df4456;
$yobota-color-hover: darken($yobota-color, 15);

$primary-brand-color: #df4456;
// $primary-brand-color: #01d263;
$secondary-brand-color: #002847;
$secondary-brand-color-dark: #002039;
$secondary-brand-color-light: #4d697e;
$secondary-brand-color-lighter: #8094a3;
$highlight-brand-color: #3b86ff;

// Supporting colors
$border-color: $secondary-brand-color;
$border-color-light: #b4b4b4;

// Shadows
$light-shadow-rgba: rgba(0, 0, 0, 0.1);
$shadow-rgba: rgba(0, 0, 0, 0.2);
$overlay-color-rgba: rgba(255, 255, 255, 0.5);
$transparent-brand-color: rgba(#df4456, 0.25);
// $transparent-brand-color: rgba(#01d263, .25);

$white: #fff;
$light-grey: #f0f1f4;
$blue: #337bff;
$green: #03ac52;
$dark-green: darken($green, 10);
$red: #d20131;
$dark-red: darken($red, 10);
$orange: #ffc107;
$grey-blue: #6c8aa2;
$dark-blue: #082e4c;

$field-normal-background: #f4f5f7;
$field-normal-border: #d8dce2;
$field-hover-background: #f4f5f7;
$field-hover-border: #a3a3a3;
$field-disabled-background: #ddddde;
$field-disabled-border: #d8dce2;

// Color transitions
$button-transition: background-color 0.2s ease;
$card-transition: background-color 0.3s ease;

//////////////////////////////////////////
/// New style colours below this line: ///
//////////////////////////////////////////

// typography
$newGrey: #707070;
$newLightGrey: #f6f8fb;
$newDarkBlue: #022847;

$graph1: #3695c1;
$graph2: #022847;
$graph3: #e66203;
$graph4: #bc1618;

:export {
  graphColor1: $graph1;
  graphColor2: $graph2;
  graphColor3: $graph3;
  graphColor4: $graph4;
}
