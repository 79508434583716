.registration-steps-container {
  background: url('../assets/yobota-background.png');
  background-repeat: repeat;
  background-size: contain;
  height: 100vh;
  padding-top: 100px;
  width: 100%;

  h1,
  h3 {
    text-align: center;
  }
}
