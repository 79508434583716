.rate-tables-badge-success {
  @extend .badge;
  @extend .badge-success;

  margin-right: 20px;
}

.rate-tables-filters {
  margin-left: 5px;
}
