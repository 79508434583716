@import '../base/colors';

.sidebar {
  background-color: $white;
  border: 1px solid $background-grey-hover;
  border-radius: 5px;
  box-shadow: 0 10px 20px $overlay-color-rgba, 0 6px 6px $light-shadow-rgba !important;
  height: 60%;
  padding: 20px;
  position: fixed;
  right: -450px;
  top: 20%;
  transition: right 0.3s ease-in-out;
  width: 450px;
  z-index: 9999;

  .sidebar-toggle {
    align-items: center;
    background: $highlight-brand-color;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 10px 20px $overlay-color-rgba, 0 6px 6px $light-shadow-rgba !important;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    left: -100px;
    padding: 10px;
    position: absolute;
    top: 50px;
    width: 100px;
    z-index: 1;

    p {
      color: $white;
    }
  }

  &.open {
    right: 0;

    .sidebar-toggle {
      left: -20px;
      width: 20px;
    }
  }
}
