.Toastify__toast-container--top-right {
  right: 15px;
  top: 95px;
  width: 450px;
}

.Toastify__toast {
  border-radius: 5px;
  padding-left: 20px;
}

.Toastify__toast--info {
  background: $blue;
}


.Toastify__toast--success {
  background: $green;
}

.Toastify__toast--warning {
  background: $orange;
}

.Toastify__toast--error {
  background: $red;
}
