@import '../base/colors';

.tabs-container {
  .extra-render-card {
    .data-table {
      &.dynamic-table {
        background: transparent;
      }

      .header {
        background-color: $dark-grey;

        p {
          color: $secondary-brand-color !important;
        }
      }
    }

    .card {
      &.card-rounded {
        background-color: $light-grey;
        margin-bottom: 20px;
      }

      p {
        padding-left: 0;
      }
    }
  }
}

.card-grouping {
  .card-holder {
    &.col-lg-3 {
      &:nth-child(4n) {
        padding-right: 0;
      }

      &:nth-child(4n + 1) {
        padding-left: 0;
      }
    }
  }
}

.deposit-management-view-one-container,
.loan-management-view-one-container {
  .tabs-container {
    .tabs-active-content {
      padding: 24px 0 0;
    }

    .tabs-content {
      padding: 24px 0 0;
    }
  }

  .dropdown-select-container {
    .dropdown-container-content {
      max-height: 400px;
    }
  }

  .card {
    p {
      font-size: 14px;
    }

    &.card-rounded {
      background-color: $white;
    }
  }

  .info-card {
    height: 100%;
  }

  .grid-container {
    max-width: unset;
  }

  .dialog-scrollable-card-list {
    .card-content {
      min-height: unset;
    }
  }

  .formset-row {
    border-bottom: 1px solid $light-grey;
  }

  .accordion-section-container {
    overflow: hidden;

    .accordion-section-head {
      padding: 0;
    }

    .accordion-section-content {
      padding: 0;

      .row {
        margin-right: 0;
      }

      .header {
        .col {
          align-items: center;
          display: flex;
          justify-content: flex-start;

          &:first-of-type {
            padding-left: 20px;
          }
        }
      }
    }

    .data-table {
      margin-bottom: 0;
      min-height: unset;
      overflow-y: auto;
    }

    .accordion-title,
    .accordion-section-head-actions {
      .material-icons {
        color: $white !important;
      }
    }
  }

  .loan-history-container {
    &.grid-container {
      padding: 5px 0;
    }
  }
}

.loan-management-gl-tool-container {
  .data-table {
    &.dynamic-table {
      .data-row-expanded-section-extra-render {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0;
      }
    }
  }
}

.download-document-button {
  align-items: center;
  background: #3b86ff;
  display: flex;
  height: calc(100% + 8px);
  justify-content: center;
  margin: -4px;
  min-height: 35px;
  width: calc(100% + 8px);

  &:hover {
    background: #082e4c;
  }
}

.dynamic-table-copy-button {
  i.material-icons {
    color: $highlight-brand-color;
    cursor: pointer;
    font-size: 14px;
    vertical-align: text-top;
  }
}
