.person-management-view-one-container {
  .dialog-scrollable-card-list {
    .card-content {
      min-height: unset;
    }
  }

  .person-management-block {
    padding: 0;
  }

  .accordion-section-content-opened {
    height: auto;
    max-height: 850px;
    overflow: auto;
    padding: 0;
  }

  .legal-documents {
    .data-table .data-row .col,
    .data-table .header .col {
      border-right: 0;
    }

    .data-table .row.data-row {
      &:nth-child(odd) {
        background: #f0f1f4;
      }
    }
  }

  .download-document-button {
    align-items: center;
    background: #3b86ff;
    display: flex;
    height: calc(100% + 8px);
    justify-content: center;
    margin: -4px;
    min-height: 35px;
    width: calc(100% + 8px);

    &:hover {
      background: #082e4c;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.person-management-view-products {
  .accordion-section-head-content {
    padding-left: 10px;
  }

  .accordion-section-content-opened {
    max-height: 1000px;
    overflow: auto;
  }
}

// Nationalities
.edit-nationalities-form {
  max-width: 400px;
}

.edit-nationalities-container {
  width: 650px;
  min-height: 700px;
}

.edit-nationalities-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.delete-button {
  padding-top: 36px;
  right: 12px;
  cursor: pointer;
}

.add-nationality-icon {
  margin-left: 5px;
}

// Holding Accounts
.ha-confirmation-popup {
  @extend .flexColumn;
  @extend .alignCenter;
  @extend .justifyCenter;
  @extend .px-2;
  border-radius: 10px;
  width: 360px;
  height: 250px;
  background: $background-grey;
}

.ha-container {
  @extend .flexColumn;
  padding-top: 1rem;
}

.ha-container-inner {
  @extend .flexRow;
  @extend .col-lg-12;
  @extend .justifyBetween;
}

.ha-account-details {
  &-id {
    @extend .flexRow;
    @extend .col-lg-6;
    @extend .justifyStart;
    @extend .alignCenter;
  }
  &-status {
    @extend .flexRow;
    @extend .col-lg-6;
    @extend .justifyEnd;
    @extend .alignCenter
  }
  &-actions {
    @extend .col-lg-12;
    @extend .justifyCenter;

    margin-top: 1.75rem;
    margin-bottom: 1rem;
  }
}

.ha-button {
  @extend .btn;
  @extend .text-regular;
}

.ha-text-link {
  color: $font-color !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    transition: .3s ease all;
  }

}

.ha-transactions-table-wrapper {
  @extend .legal-documents;
  @extend .col;
  @extend .col-lg-12;

    .data-table .header.sticky {
      margin-right: 0;
    }

    .data-table.dynamic-table {
      @extend .my-2;
      border: 2px solid $field-normal-border !important;
      border-radius: .5rem;
    }
}

